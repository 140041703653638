import { reactive } from "vue";


const state = reactive({
    selectedLanguage: null,   


// SECTION ENTETE
header : {

    

    title : {
       
            title:{
                fr : `Agro-Horizon`,
                en : `Agro-Horizon`   
            },

            underTitle:{
                fr : `Bâtissons l’avenir maintenant!`,
                en : `Building the Future Now!`
            }
               
        


 
        
    },
    
    subText : {
        fr : `Nous sommes un consortium immobilier visant à faire une véritable différence dans cette industrie grâce à notre respect des cibles en matière de développement durable et de responsabilisation environnementale et à une culture organisationnelle imprégnée d'une préoccupation pour les enjeux de société.`,
        en : `We are a real-estate consortium aiming to make a real difference in the industry through our commitment to goals in matters of sustainable development and environmental accountability, and to a corporate culture with a deep concern for social issues.`,
    
    }
    

},

// SECTION VISION

vision : {
    
        anchor : {
            fr : `Notre vision`,
            en : `OUR VISION`,
        },

        title : {
            fr : `Nous distinguer dans <br/>tous nos projets!`,
            en : `Stand out in <br/>all our projects!`
        },

        titleMobile : {
            fr : `Nous distinguer<br / > dans tous<br/> nos projets!`,
            en : `Stand out<br/> in all our <br/> projects!`,
        },

        titleTablette: {
             
                fr : `Nous distinguer dans tous nos projets!`,
                en : `Stand out in all our projects!`
            
        },
    
        text : {
            fr: `Pour Agro-Horizon, le succès de tout projet passe par quatre conditions fondamentales : l’expertise et la crédibilité de l’équipe, la synergie entre les occupants, la mise en place d’une structure flexible et la réalisation rapide d’interventions concrètes, le tout de concert avec les valeurs de l’entreprise!
            <br/><br/>
            Agro-Horizon a l’honneur de compter sur une équipe d'experts pluridisciplinaires qui nous permet d’imaginer, de planifier, de construire et de gérer un projet dans la cohésion et l'harmonie.`,
            en: `For Agro-Horizon, the success of any project relies on four crucial conditions: the expertise and credibility of our team, the synergy among occupants, the implementation of a flexible structure, and the efficient achievement of tangible interventions all while keeping aligned with our core values!
            <br/><br/>
            We are honoured to count on the collaboration of a team of pluridisciplinary experts, allowing the company to plan, build, and manage projects in cohesiveness and harmony.`
        }
},

// SECTION PROACTIVE

proactive : {

    title : {
        fr : `La proactivité pousse <span style="white-space: nowrap">Agro-Horizon</span> à poser les actions nécessaires lui permettant de rester à la fine pointe des innovations du secteur de l'immobilier et de continuellement s'améliorer grâce à des formations et des conférences traitant des meilleures pratiques en matière de développement immobilier durable.`,
        en : `Proactivity drives <span style="white-space: nowrap">Agro-Horizon</span> to take the necessary actions to stay on the cutting edge of real estate innovations, and to continuously improve through training and conferences on best practices in the field of sustainable real-estate development.`
    },





    environnementale : {
        title : {
            fr : `Environnemental`,
            en : `Environmental`
        },
        text : {
            fr : `Réduire les émissions de gaz à effet de serre et de toute autre substance affectant la couche d’ozone; permettre une meilleure gestion de l’énergie; protéger la biodiversité; promouvoir l’économie circulaire.`,
            en : `Reduce greenhouse gas emissions and any other substance aecting the ozone layer; allow better energy management; protect biodiversity, promote circular economy.`
        }

    },

    social : {
        title : {
            fr : `Social`,
            en : `Social`
        },
        text : {
            fr: `Permettre un transfert des connaissances et des compétences à l’ensemble des parties prenantes; permettre une répartition adéquate des richesses; favoriser l’ancrage dans le milieu.`,
            en: `Enable the transfer of knowledge and skills to all stakeholders; allow for adequate distribution of whealth; foster the rooting of the project in the community.`
        }
    },

    economique :  {
        title : {
            fr : `Économique`,
            en : `Economic`
        },
        text: {
            fr: `Augmenter l'efficacité de l’entreprise et du secteur d’activité; favoriser les bonnes pratiques et les relations commerciales; favoriser l’innovation; favoriser l’approvisionnement responsable.`,
            en: `Increase the company's and the industry's efficiency; promote best practices and business relationships; favour and promote innovation and responsible procurement practices.`
        }

    }
    },




// FOOTER DE PAGE    

footer : {

 
    title : {
        fr : `Au cœur de nos préoccupations et de notre mission se trouve les racines de notre slogan. Nous accordons une importance cruciale à l'impact, au caractère innovant et à la pérennité de tous nos projets immobiliers. Ensemble nous sommes tournés vers le futur, ensemble nous bâtissons notre futur.<br/> BÂTISSONS L'AVENIR MAINTENANT!`,
        en : `The inspiration for our slogan takes root at the core of our concerns and mission. We consider the impact, innovative nature, and sustainability of all our real-estate projects of paramount importance. Together we look to the furure, together we are<br/> BUILDING THE FUTURE NOW!`
        
    },

    titleMobile : {
        fr : `Au cœur de nos préoccupations et de notre mission se trouve les racines de notre slogan. Nous accordons une importance cruciale à l'impact, au caractère innovant et à la pérennité de tous nos projets immobiliers. Ensemble nous sommes tournés vers le futur, ensemble nous bâtissons notre futur.<br/> BÂTISSONS L'AVENIR MAINTENANT!`,
        en : `The inspiration for our slogan takes root at the core of our concerns and mission. We consider the impact, innovative nature, and sustainability of all our real-estate projects of paramount importance. Together we look to the furure, together we are <br/>  BUILDING THE FUTURE NOW!`
    },

  
    
    contact : {

    //    SECTION COMMUNIQUER  

      communiquer : {
        title : {
            fr : `POUR COMMUNIQUER`,
            en : `CONTACT US`
        },
        tel : {
            fr : `T 418 803-6668`,
            en : `T 418 803-6668`,
            link : `tel:4188036668`
        },
        mail : {
            fr : `info@agrohorizondev.com`,
            en : `info@agrohorizondev.com`,
            link : `mailto:info@agrohorizondev.com`
        }
     
      },

    //   SECTION NOTRE ADRESSE


    address : {
        title : {
            fr : `NOTRE ADRESSE`,
            en : `OUR ADDRESS`
        },
        address : {
            fr : `3030, boul. Le Carrefour,<br/> bur. 1002<br/> Laval (Québec) H7T 2P5`,
            en : `3030, boul. Le Carrefour<br/> office 1002<br/> Laval (Québec) H7T 2P5`
    },


    //   SECTION RESEAUX SOCIAUX 
      
      socialNetwork : {
        title : {
            fr : `SUIVEZ-NOUS`,
            en : `FOLLOW-US`
        },
        facebook : {
          
            link : `https://www.facebook.com/Agro-Horizon-Dev-109846672409878/`
      },

      linkedin : {
    
        link : `https://www.linkedin.com/company/agrohorizon/about/`
      },
      

      }
    }
    }

}})










const methods = {


goUp(){
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
      });
    
  }
}





const getters = {};

export default {
  state,
  methods,
  getters,
};
