<template>
  <div class="home-container">
  
    <header id="top"> 

    <Header />

    </header>

    <section>
      <Vision />
    </section>

    <footer>
      <Footer />

    </footer>


  </div>





</template>



<script setup>

// INCLUDES COMPONENTS
import Vision from '@/components/Vision.vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

// OTHERS FUNCTIONS

import { useRoute, useRouter } from 'vue-router';
import { onMounted, inject } from 'vue';










const route = useRoute();
const router = useRouter();
const store = inject('store');



onMounted(() => {



  // SI LANGUAGE EST EN FRANCAIS

  if(route.params.lang === 'fr'){
        store.state.selectedLanguage = 'fr';

  // SI LA LANGUAGE EST EN ANGLAIS
  }else if(route.params.lang === 'en'){
    store.state.selectedLanguage = 'en';
  }else{
    // SINON ON REDIRECT A PAGE FRANCAIS PAR DEFAULT
    router.push({ path: '/fr' });
  }
})






</script>



<style>



</style>