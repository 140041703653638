<template>
  <div class="image-champs flex justify-center items-center">
    <div class="text-champ md:my-10 xl:my-0">
      <h4 v-html="store.state.footer.title[store.state.selectedLanguage]" class="text-center hidden md:block"></h4>
      <h4 v-html="store.state.footer.titleMobile[store.state.selectedLanguage]" class="text-center block md:hidden"></h4>

    </div>

  </div>
  <div class="footer-bleu grid grid-cols-1 md:grid-cols-3">
    <div class="flex pl-4 md:pl-8 md:justify-center">
<div class="logo-agro-inverse mt-3  md:pt-24 md:pl-4 ">

  <img src="../assets/images/agroHorizon_Inverse.svg" alt="Agro Horizon" class="logo-horizon-inverse" v-if="store.state.selectedLanguage === 'fr'">
  <img src="../assets/images/AgroHorizon_LogoPhrase_Inverse_ANG.svg" alt="Agro Horizon" class="logo-horizon-inverse" v-else>
</div>

    </div>
<div class="flex md:justify-center">



<!-- COMMUNIQUER  -->
<div class="flex pl-8 md:pl-0 md:items-center flex-col ">


  <div class="largeur-comm">

    
      <p class="titre-adresse pt-10 md:pt-32">
        {{store.state.footer.contact.communiquer.title[store.state.selectedLanguage]}}
      </p>
      <div class="flex flex-col">

     <a class="links-footer pt-3" :href="store.state.footer.contact.communiquer.tel.link">{{store.state.footer.contact.communiquer.tel[store.state.selectedLanguage]}}</a>
     <a class="links-footer" :href="store.state.footer.contact.communiquer.mail.link">{{store.state.footer.contact.communiquer.mail[store.state.selectedLanguage]}}</a>
      </div>
  </div>
  <div class="largeur-comm">
    <p class="titre-adresse pt-10">
        {{store.state.footer.contact.address.title[store.state.selectedLanguage]}}
      </p>
      <p class="links-footer pt-3" v-html="store.state.footer.contact.address.address[store.state.selectedLanguage]"></p>
  </div>
</div>

</div>



<!-- SOCIAL + CHOIX DE LANGUAGES -->

<div class="flex md:justify-center">
  <div class="pl-8 md:pl-0 w-full md:w-auto">

  
<p class="titre-adresse pt-14 md:pt-32">{{store.state.footer.contact.address.socialNetwork.title[store.state.selectedLanguage]}}</p>
<div class="flex justify-between items-center pt-4 md:pt-6 w-full ">
  <div class="flex justify-start  ">
  <a :href="store.state.footer.contact.address.socialNetwork.facebook.link" target="_blank">

<svg   class="social-mobile mr-4 social" data-name="Composant 6 – 1" xmlns="http://www.w3.org/2000/svg" width="45.685" height="45.686" viewBox="0 0 45.685 45.686">
  <path id="Tracé_56" data-name="Tracé 56" d="M283.516,683.615v-.861a21.124,21.124,0,1,1,14.927-6.187,21.053,21.053,0,0,1-14.927,6.187v1.728a22.843,22.843,0,1,0-22.842-22.843,22.842,22.842,0,0,0,22.842,22.843Z" transform="translate(-260.673 -638.796)"/>
  <path id="Tracé_57" data-name="Tracé 57" d="M267.124,667.461h5.348v-13.4h3.734l.4-4.482h-4.131v-2.558c0-1.058.214-1.473,1.236-1.473h2.9v-4.655H272.9c-3.976,0-5.772,1.751-5.772,5.107v3.579h-2.786v4.541h2.786Z" transform="translate(-247.626 -631.332)"/>
</svg>

    
    </a>
  <a :href="store.state.footer.contact.address.socialNetwork.linkedin.link" target="_blank">
  <svg class="social-mobile social" data-name="Composant 7 – 1" xmlns="http://www.w3.org/2000/svg" width="45.685" height="45.69" viewBox="0 0 45.685 45.69">
    <g id="Groupe_20" data-name="Groupe 20">
      <path id="Tracé_53" data-name="Tracé 53" d="M297.692,683.914v-.866a21.108,21.108,0,1,1,14.927-6.182,21.039,21.039,0,0,1-14.927,6.182v1.733a22.845,22.845,0,1,0-22.842-22.843,22.842,22.842,0,0,0,22.842,22.843Z" transform="translate(-274.85 -639.09)"/>
      <rect id="Rectangle_14" data-name="Rectangle 14" width="4.548" height="14.694" transform="translate(12.877 17.946)"/>
      <path id="Tracé_54" data-name="Tracé 54" d="M280.268,646.836a2.711,2.711,0,1,0-2.69-2.713A2.7,2.7,0,0,0,280.268,646.836Z" transform="translate(-265.14 -630.814)"/>
      <path id="Tracé_55" data-name="Tracé 55" d="M283.669,650.355c0-2.065.953-3.3,2.772-3.3,1.673,0,2.476,1.181,2.476,3.3v7.715h4.527v-9.306c0-3.935-2.23-5.836-5.344-5.836a5.129,5.129,0,0,0-4.432,2.426v-1.979H279.31v14.7h4.359Z" transform="translate(-258.975 -625.429)"/>
    </g>
  </svg>

    </a>

  </div>

  <div class="md:hidden link-up-footer">
       <button @click="store.methods.goUp()"><img src="../assets/images/carre-vert-haut.svg" class="img-mobile"></button>        <!-- ONLY ON MOBILE -->

  </div>
</div>
<div class="pt-10 pb-16 md:pb-0 md:pt-14 link-language">
    <router-link  to="/fr" v-if="store.state.selectedLanguage === 'en'" @click="store.state.selectedLanguage = 'fr'">Francais</router-link>
   <router-link to="/en" v-if="store.state.selectedLanguage === 'fr'" @click="store.state.selectedLanguage = 'en'">English</router-link>
</div>
</div>
  </div>
  </div>

</template>



<script setup>
  import { inject } from 'vue';
  const store = inject('store');



</script>



<style>







.image-champs{
  min-height: 332px;
  background-image: url('../assets/images/champs.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.text-champ{
      padding: 0 10% 0 10%;
      font-size : 25px;
      font-weight: bold;
      color: #fcfcfc;
      line-height:44px;
}

.footer-bleu{
  background-color: #1A344F;
  min-height: 484px;
}

.logo-agro-inverse{

 max-width: 325px;

}

.titre-adresse{
  color: #65B63D;
  font-size: 14px;
  letter-spacing: 0.77px;
  font-weight: 600;
}

.links-footer{
   color: #FCFCFC; 
  font-size: 14px;
  line-height: 24px;
}

.liens-adresse{
  font: normal normal normal 14px/24px Spartan;
  letter-spacing: 0px;
  color: #FCFCFC;
}

.largeur-comm{
  width: 195px;
}

.social{  
  fill: #FCFCFC;
  transition-delay: 0.2s;
  transition: fill 0.3s ease-in-out 
}


.social:hover{
  fill: #65B63D;
}




@media only screen and (max-width: 767px) {

.image-champs{
  min-height: 400px;
  background-image: url('../assets/images/champ-mobile.jpg');

}

.text-champ{

      font-size : 16px;
      font-weight: bold;
      line-height:27px;
}

.logo-agro-inverse{
  
 max-width: 220px;
}

.social-mobile{
    height:31px;
    width:31px;
}

.link-up-footer{

  padding-right: 38px;
}




}




/* VERSION GRANDE TABLETTE */

@media only screen and (min-width: 768px) and (max-width: 1280px)  {

.logo-agro-inverse{
 padding-right: 10px;
}


.text-champ{
      font-size:20px;
      line-height: 37px;
    
}





}








</style>