import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const lang = navigator.language.split('-')[0];
let toRedirect;
if(lang === 'fr'){
  toRedirect = '/fr';
}else{
  toRedirect = '/en';
}


const routes = [
  {
    path: '/:lang',
    name: 'Home',
    component: Home
  },
  {
    path: '/',
    redirect:  
    toRedirect
  },

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
