<template>
<router-view/>
</template>
<script>
import './assets/tailwind.css';
import store from './store/index';
import { provide } from 'vue';
export default {
  name: 'App',
  setup () {
    provide('store', store)

    return {

    }
  }
}
</script>
<style>

@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@400;500;600;700;800&display=swap');

#app {
 font-family: 'Spartan', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;


}

</style>
