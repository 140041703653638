<template>
  <div class="header-container min-h-screen">
      <div class="header-inside flex flex-col lg:justify-between justify-end px-10 relative">

          <!-- TOP HEADER -->
          <div class="w-100 pt-10 link-language hidden lg:flex justify-between">
                <!-- SELECTION LANGUAGE -->
                <router-link to="/fr" v-if="store.state.selectedLanguage === 'en'" @click="store.state.selectedLanguage = 'fr'">Francais</router-link>
                <router-link to="/en" v-if="store.state.selectedLanguage === 'fr'" @click="store.state.selectedLanguage = 'en'">English</router-link>
                <!-- LOGO AGRO -->
                <img src="../assets/images/agrohorizon_logo.svg" alt="Agro Horizon" class="header-logo" />
          </div>

            <!-- TITRE HEADER INTRO -->
          <div class="text-header-container hidden lg:block">

                <h1 class="text-header" id="text-one-header" >{{store.state.header.title.title[store.state.selectedLanguage]}}<br/>
                    <span class="underTitle">{{store.state.header.title.underTitle[store.state.selectedLanguage]}}</span>
                </h1>
      

          </div>
        <!-- ONLY ON MOBILE  | LOGO INTRO HEADER -->
        <div class="lg:hidden absolute block-logo-header-mobile block">      
                <img src="../assets/images/agroHorizon_Inverse.svg" alt="Agro Horizon" class="logo-horizon-inverse-header" v-if="store.state.selectedLanguage === 'fr'">
                <img src="../assets/images/AgroHorizon_LogoPhrase_Inverse_ANG.svg" alt="Agro Horizon" class="logo-horizon-inverse-header" v-else>

        </div>

        <!-- FLECHE EN BAS -->
          <div class="w-100 mx-auto mb-32 fleche-container">
              <img src="../assets/images/fleche-droite.svg" alt="Flèche-bottom" class="fleche-bottom-header" >
          </div>
      </div> 
  
  </div>

  <!-- SOUS-TEXTE TITRE -->
  <div class="under-header">
      <div class="under-header-text-container text-center mx-auto">
      <h2 class="text-under-header">
          {{store.state.header.subText[store.state.selectedLanguage]}}
      </h2>

      </div>
  </div>

</template>



<script setup>
import { inject, onMounted } from 'vue';
import gsap from "gsap";


const store = inject('store');



onMounted(() => {

            // ANIMATIONS MOBILE
            gsap.from('.block-logo-header-mobile', { opacity: 0, duration: 1, delay: 0.2 })
            gsap.from('.fleche-container', { opacity: 0, duration: 0.4, delay: 0.6 })

            // ANIMATION DESKTOP
            gsap.from('#text-one-header', { x: +100, opacity: 0, duration: 1, delay: 0.2 })
      
    })
</script>



<style>

.link-language{
    width: 100% !important;
}





.link-language a{
    color: #fcfcfc;
    text-decoration: none;
    font-size: 15px;
    transition-delay: 0.2s;
    transition: fill 0.3s ease-in-out 
    
}

.link-language a:hover{
    color: #65B63D;
   
}

.under-header{
    background-color: #1A344F;
    height: 370px;
   
}

.under-header-text-container{
    max-width: 900px;
}


.header-logo{
    width: 260px;
}

.header-container {

    background-image:
    linear-gradient(to bottom,transparent 30%,  #1A344F0D , #1A344F),
    url('../assets/images/header_bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}


.header-inside{
    max-width: 1445px;
    margin-left: auto;
    margin-right: auto;
    min-height: 100vh;
}





.text-header{
    font-size: 90px;
    line-height: 106px;
    font-weight: 800;
    letter-spacing: 0;
    color: #fcfcfc;
}

.underTitle{
    font-size: 60px;
    font-weight: 600;
}

.block-logo-header-mobile{
    top: 50%;
    transform: translateY(-60%);
    margin-right: 25px;
}



.text-under-header{
    font-size:25px;
    color:#fcfcfc;
    font-weight: normal;
    line-height: 45px;
}






@media only screen and (min-width: 470px) and (max-width: 1280px)  {

  .text-under-header{
        font-size:20px;
        line-height: 37px;
        padding: 20px 45px 0 45px;
     
    }



    .header-inside{
        max-width: 1445px;
        margin-left: auto;
        margin-right: auto;
        min-height: 100vh;
        align-items: center;
    }

    .logo-horizon-inverse-header{
        max-width: 406px;

    }



    .fleche-bottom-header{
        height: 55px;
    }



}

/* VERSION MOBILE */

@media only screen and (max-width: 767px) {

    .text-under-header{
        font-size:16px;
        line-height: 27px;
        padding: 20px 45px 0 45px;
    }


    .fleche-bottom-header{
        height: 55px;
    }


    .fleche-bottom-header{
        height: 55px;
    }

    .under-header{

            height: auto;
            padding-bottom: 30px;
}








}











</style>